import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { setDefaultOptions } from 'date-fns';
import { enGB, de, fr } from 'date-fns/locale';
import type { Locale } from 'date-fns';
import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

const locales: { [key: string]: Locale } = {
  de: de,
  en: enGB,
  fr: fr,
};

export const DateFnsLocaleProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setDefaultOptions({ locale: locales[i18n.language] || enGB });
  }, [i18n.language]);

  const currentLocale = locales[i18n.language] || enGB;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLocale}>
      {children}
    </LocalizationProvider>
  );
};
